<template>
  <div
    :id="id"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <div
      v-if="!innerHTML"
      v-html="html"
    />
  </div>
</template>
<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import htmlContentStoreModule from './htmlContentStoreModule'
import store from '@/store'

export default {
  components: {
    BOverlay,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    refresh: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
    innerHTML: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'html-content'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, htmlContentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router } = useRouter()

    const apiId = props.apiId || router.currentRoute.params.id
    const params = router.currentRoute.query

    const html = ref('')
    const loading = ref(false)
    const id = Date.now()

    const fetchData = () => {
      loading.value = true
      store.dispatch('html-content/fetchData', {
        api: props.api,
        apiId,
        params,
      })
        .then(response => {
          const { data } = response
          html.value = data
          loading.value = false
          if (props.innerHTML) {
            document.getElementById(id).parentNode.innerHTML = html.value
          }
        })
        .catch(() => {
          html.value = ''
          loading.value = false
        })
    }

    if (props.api) {
      if (props.loadByTab) {
        if (props.currentTab === props.loadByTab) {
          fetchData()
        }
      } else {
        fetchData()
      }
    }

    watch(() => props.currentTab, () => {
      if (props.currentTab === props.loadByTab) {
        fetchData()
      }
    })

    watch(() => props.refresh, () => {
      fetchData()
    })

    return {
      loading,
      html,
      id,
    }
  },
}
</script>
